
































































import Vue from 'vue'
import Component from 'vue-class-component'
import { User } from '@/types'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import { Gender, SubscriptionStatus } from '@/api'
import { formatDate } from '@/util/formatDate'

@Component({
  components: {
    Content,
    Toolbar
  }
})
export default class UserDetail extends Vue {
  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get birthDate () {
    return formatDate(this.currentUser.employeeDetails?.birthDate || '')
  }

  get hasActiveSubscription () {
    return this.currentUser.subscription?.subscriptionStatus === SubscriptionStatus.Active
  }

  get canEditDetails () {
    return this.currentUser?.subscription && this.hasActiveSubscription
  }

  public getGender (gender: Gender) {
    switch (gender) {
      case Gender.Female:
        return 'Vrouw'
      case Gender.Male:
        return 'Man'
      case Gender.X:
        return 'X'
      case Gender.Unknown:
        return 'Zeg ik liever niet'
      default:
        return 'Onbekend'
    }
  }
}
